import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src750413537/src/src/layout/defaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Stack = makeShortcode("Stack");
const Text = makeShortcode("Text");
const Heading = makeShortcode("Heading");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section mdxType="Section">
      <h1 {...{
        "id": "typography"
      }}>{`Typography`}</h1>
      <Stack mdxType="Stack">
        <Text variant="gigaBold" fontFamily="title" mb={3} mdxType="Text">
  Ivy Hournal - Semi Bold - used for headings
        </Text>
        <p>{`Nunito regular is used for body copy and sub headings`}</p>
      </Stack>
    </Section>
    <Section bg="blueLight" mb={4} mdxType="Section">
      <h2 {...{
        "id": "hierarchy"
      }}>{`Hierarchy`}</h2>
      <Stack my={3} mdxType="Stack">
  <Text mdxType="Text">Product overview header</Text>
  <Heading fontFamily="title" variant="exaBold" mdxType="Heading">
    This is a main headline
  </Heading>
  <Text mdxType="Text">
    Body copy lorem ipsum dolor sit amet, consectetur adipiscing consectetur
    adipiscing elitelit.
  </Text>
      </Stack>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      